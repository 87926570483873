<template>
    <div class="login-container">
        <el-carousel indicator-position="none" style="position:absolute; top:0;bottom:0;left:0;right:0;"> 
            <el-image :src="management.topImage" style="position: absolute; width: 100%; height: 100%;"></el-image>
        </el-carousel>
        <el-row :gutter="20"
            style="position: absolute; width: 75%; height: 460px; top: 0; bottom: 0; left: 0; right: 0; margin: auto">
            <el-col :span="12" style="color: #fff">
                <div style="height: 300px"></div>
            </el-col>
            <el-col :span="12" class="title-container">
                <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
                    label-position="left">
                    <h3 class="title">{{ $t('common.loginTitle') }}</h3>
                    <el-form-item prop="userName" style="margin-bottom: 30px; height: 56px; background-color: #fff">
                        <span class="svg-container">
                            <!-- <svg-icon icon-class="user" /> -->
                            <i class="el-icon-user"></i>
                        </span>
                        <el-input ref="userNameRef" v-model="loginForm.userName" :placeholder="$t('common.userName')"
                            name="userName" type="text" tabindex="1"
                            style="font-size: 18px; color: rgb(33, 50, 94) !important" auto-complete="on" />
                    </el-form-item>

                    <el-form-item prop="password" style="margin-bottom: 50px; height: 56px; background-color: #fff;">
                        <span class="svg-container">
                            <svg-icon icon-class="password" />
                        </span>
                        <el-input ref="passwordRef" :key="passwordType" v-model="loginForm.password"
                            :type="passwordType" :placeholder="$t('common.passWord')" name="password" tabindex="2"
                            style="font-size: 18px; color: rgb(33, 50, 94);" auto-complete="on"
                            @keyup.enter="handleLogin" />
                        <el-tooltip :content="passwordType == '' ? '隐藏密码' : '显示密码'" placement="right">
                            <span class="show-pwd" @click="showPwd">
                                <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
                            </span>
                        </el-tooltip>
                    </el-form-item>
                    <el-button size="large" :loading="loading" type="primary" :round=true
                        style="width: 100%; height: 56px; background-color: rgb(33, 50, 94); font-size: 24px; font-weight: bold; border-radius: 10px;"
                        @click.prevent="handleLogin">
                        登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录
                    </el-button>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import management from "/public/Json/management.json";

export default {
    name: "Management_Login",
    components: {
    },
        
    data() {
        return {
            management,
            loginForm: {
                userName: '',
                password: '',
            },
            redirect: '',
	
            loginRules: {
                userName: [{ required: true, trigger: 'blur' }],
                password: [{ required: true, trigger: 'blur' }],
            },
            loading: false,
            passwordType: 'password',
            otherQuery: {},
            showCopyright: true,
        };
    },
    mounted: function () {
        
    },
    methods: {
            handleLogin() { 
        }
        
    }
};
 
</script>

<style lang="scss" >
$bg: #283443;
$light_gray: #fff;
$cursor: rgb(33, 50, 94);

/* reset element-ui css */
.login-container {
	.title-container {
		position: relative;

		.title {
			font-size: 26px;
			color: $light_gray;
			margin: 0px auto 40px auto;
			text-align: center;
			font-weight: bold;
		}

		.set-language {
			color: #fff;
			position: absolute;
			top: 3px;
			font-size: 18px;
			right: 0px;
			cursor: pointer;
		}
	}

	.el-input {
		display: inline-block;
		height: 47px;
		width: 85%;

		input {
			background: transparent;
			border: 0px;
			-webkit-appearance: none;
			border-radius: 0px;
			padding: 12px 5px 12px 15px;
			color: rgb(33, 50, 94);
			height: 47px;
			caret-color: $cursor;

			&:-webkit-autofill {
				box-shadow: 0 0 0px 1000px #fff inset !important;
				-webkit-text-fill-color: $cursor !important;
			}
		}
	}

	.el-input__inner {
		&:hover {
			border-color: var(--el-input-hover-border, var(--el-border-color-hover));
			box-shadow: none;
		}

		box-shadow: none;
	}

	.el-input__wrapper {
		display: contents !important;
	}

	.el-form-item {
		border: 1px solid rgba(255, 255, 255, 0.1);
		background: rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		color: #454545;
	}

	.copyright {
		width: 100%;
		position: absolute;
		bottom: 0;
		font-size: 12px;
		text-align: center;
		color: #cccccc;
	}
}
</style>
	
<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

:v-deep(.el-carousel__container){
	height: 100% !important;
}

.login-container {
	// position: relative;
	// min-height: 100%;
	// width: 100%;
	// overflow: hidden;
	// background: url('/login_background.jpg') no-repeat;
	// background-size: 100% 100%;
	// background-attachment: fixed;

	.login-form {
		position: relative;
		width: 460px;
		height: 460px;
		padding: 40px 50px 0 50px;
		margin: 0 auto;
		overflow: hidden;
		background-color: rgba($color: #ffffff, $alpha: 0.9);
		border-radius: 15px;
	}

	.tips {
		font-size: 14px;
		color: #fff;
		margin-bottom: 10px;

		span {
			&:first-of-type {
				margin-right: 16px;
			}
		}
	}

	.svg-container {
		padding: 6px 5px 6px 15px;
		color: $dark_gray;
		vertical-align: middle;
		width: 30px;
		display: inline-block;
	}

	.title-container {
		position: relative;

		.title {
			font-size: 38px;
			color: rgb(190, 198, 218);
			margin: 0px auto 50px auto;
			text-align: center;
			font-weight: normal;
		}
	}

	.show-pwd {
		position: absolute;
		right: 4px;
		top: 11px;
		font-size: 16px;
		color: $dark_gray;
		cursor: pointer;
		user-select: none;
	}

	.captcha {
		position: absolute;
		right: 0;
		top: 0;

		img {
			height: 52px;
			cursor: pointer;
			vertical-align: middle;
		}
	}
}
</style>